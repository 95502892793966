import React from "react";
import styled from "styled-components";
import { theme } from "../styles/theme";
import Logo from "../../assets/icons/ifg_logo_icon.svg";
import { CURRENCY } from "../../constants/currency";

const LayoutContainer = styled.div``;

export const NavbarContainer = styled.nav`
  padding: 0.8em 2em;
  background-color: ${theme.colors.Primary02};
  @media (max-width: 425px) {
    display: flex;
    justify-content: center;
    justify-content: center;
  }
`;

const PageContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 649px;
  padding: 2em 4em;
  background-color: ${theme.colors.Primary02};
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  @media (max-width: 649px) {
    background-color: inherit;
    width: 100%;
    padding: 0 3em;
    box-shadow: none;
  }
`;

const DesktopTitleContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  @media (max-width: 649px) {
    display: none;
  }
`;

const DesktopTitle = styled.p`
  color: ${theme.colors.Primary03};
  font-weight: 700;
  font-size: 1.5rem;
`;

const TotalIndicator = styled.p`
  font-size: 0.855rem;
  margin-bottom: 0.75rem;
  color: ${theme.colors.Font01};
`;

const Total = styled.p`
  color: ${theme.colors.Primary01};
  font-weight: 700;
  font-size: 1.5rem;
`;

const TotalContainer = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;

interface PaymentLayoutProps {
  title?: string;
  total: number;
  currency?: CURRENCY;
}
const PaymentLayout: React.FC<PaymentLayoutProps> = ({
  children,
  title,
  total,
  currency = CURRENCY.POUND,
}) => {
  return (
    <LayoutContainer>
      <NavbarContainer>
        <Logo />
      </NavbarContainer>
      {title && (
        <DesktopTitleContainer>
          <DesktopTitle>{title}</DesktopTitle>
        </DesktopTitleContainer>
      )}
      <PageContainerWrapper>
        <PageContainer>
          <TotalContainer>
            <TotalIndicator>Total</TotalIndicator>
            <Total>{`${currency}${total}`}</Total>
          </TotalContainer>
          {children}
        </PageContainer>
      </PageContainerWrapper>
    </LayoutContainer>
  );
};

export default PaymentLayout;

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { NavbarContainer } from "../../layouts/PaymentLayout";
import Logo from "../../../assets/icons/ifg_logo_icon.svg";
import CheckIcon from "../../../assets/icons/check_icon.svg";
import Button, {
  ButtonStates,
} from "../../../legacyComponents/Button/Button.component";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em 4em;
  height: 80vh;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  max-width: 400px;
`;

const IconTextWrapper = styled.div`
  display: grid;
  gap: 2em;
  min-height: 100px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCheckMark = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.Primary01};
  border-radius: 100%;
  height: 12vw;
  width: 12vw;
  min-width: 80px;
  min-height: 80px;
  border: solid 5px ${theme.colors.BrandNavy};
  & > svg {
    width: 6vw;
    height: 6vw;
    min-width: 40px;
    min-height: 40px;
  }
`;

const TextContainer = styled.div`
  display: grid;
  text-align: center;
  gap: 1.2em;
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 1.5em;
  color: ${theme.colors.BrandNavy};
`;
const SubText = styled.p`
  font-size: 1em;
  color: ${theme.colors.Grey02};
`;

const PaymentSuccessConfirmation: React.FC = () => {
  return (
    <div>
      <NavbarContainer>
        <Logo />
      </NavbarContainer>
      <PageWrapper>
        <PageContainer>
          <IconTextWrapper>
            <IconContainer>
              <StyledCheckMark>
                <CheckIcon />
              </StyledCheckMark>
            </IconContainer>
            <TextContainer>
              <Text>Congratulations</Text>
              <SubText>{"You are all set"}</SubText>
            </TextContainer>
          </IconTextWrapper>
          <Link to="/app/summary">
            <Button version={ButtonStates.PRIMARY} text="Go back home" />
          </Link>
        </PageContainer>
      </PageWrapper>
    </div>
  );
};

export default PaymentSuccessConfirmation;
